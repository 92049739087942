<template>
  <div>
    <TopNav></TopNav>
    <div class="first"></div>
    <div class="second">
      <div class="topNav">
        <div :class="['topNav-item',navIndex == 0?'topNav-item-active':'' ]" @click="changeNav(0)">县乡村一体化</div>
        <div :class="['topNav-item',navIndex == 1?'topNav-item-active':'' ]" @click="changeNav(1)">5G智能国医馆</div>
        <div :class="['topNav-item',navIndex == 2?'topNav-item-active':'' ]" @click="changeNav(2)">智能云诊室</div>
      </div>
      <div class="content">
        <div class=" animated fadeInLeft">
          <img src="../static/img/classicCase/left.png" height="443" width="605"/>
        </div>

        <div class="content-r animated fadeInRight">
          <div style="height: 450px">
            <div class="content-r-item" v-for="(n,i) in nowList" :key="i">
              <div class="content-r-item-img">
                <!--              <img src="../static/img/contact/com-img-6.png" height="122" width="188"/>-->
                <img style="object-fit: cover" :src="n.url" height="122" width="188"/>
              </div>
              <div class="content-r-item-txt">
                <p :title="n.name">{{n.name}}</p>
                <div>
                  {{n.cont[0]}}
                </div>
              </div>
            </div>
          </div>

          <el-pagination
              :page-size="3"
              :total="total"
              @current-change="pageChange"
              background
              layout="prev, pager, next"
              v-if="total>3"
          />
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import TopNav from "@/components/Nav/TopNav.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "ClassicCase",
  data() {
    return {
      lists: [1, 2, 3],
      navIndex: 0,
      total: 0,
      content: '郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改郸城县把紧密型县域医共体建设作为深化医改',
      list: [],
      list1: [
        {name:'河南省周口市郸城县',url:require('../static/img/classicCase/img-1.jpg'),cont:['郸城县中医院始建于1987年，是一所综合性国家三级中医院，通过云平台进行县乡村一体化部署，打造“郸城模式”，成效显著。']},
        {name:'河南省开封市兰考县',url:require('../static/img/classicCase/img-2.jpg'),cont:['兰考县通过云平台部署，使县域区内4家县级医院、13家乡镇卫生院及566家村卫生室实现智慧开方，提供及时、有效、便捷的医疗服务。']},
        {name:'四川省南充市第三人民医院',url:require('../static/img/classicCase/img-3.png'),cont:['医院始建于1953年，是顺庆区唯一一所二级甲等综合性医院，通过云平台进行市县乡村一体化部署，全面提高南充市整体中医诊疗水平。']},
        {name:'河南省南阳市唐河县',url:require('../static/img/classicCase/img-4.png'),cont:['唐河县中医院是一所集中西医医疗、教学、科研、保健、康复为一体的国家二级甲等中医医院，南阳市中医药综合改革国家试验区。']},
        {name:'河南省鹤壁市浚县',url:require('../static/img/classicCase/img-5.png'),cont:['浚县中医院一所集中西医   、教学、科研、保健、康复为一体的县级医院，通过云平台县乡村一体化部署，全面提高县域内中医诊疗水平。']},
      ],
      list2: [
        {name:'河南省周口市郸城县巴集乡卫生院',url:require('../static/img/classicCase/img-6.jpg'),cont:['巴集乡卫生院是郸城县第二医疗集团成员单位，目前已通过云平台成功打造“5G智能国医馆”，帮助基层医生开具疗效可靠的处方。']},
        {name:'河南省周口市郸城县胡集乡卫生院',url:require('../static/img/classicCase/img-7.jpg'),cont:['胡集乡卫生院是郸城县第二医疗集团成员单位，云平台“5G智能国医馆”已成功上线，大大提升基层中医诊疗服务水平。']},
        {name:'河南省周口市郸城县李楼乡卫生院',url:require('../static/img/classicCase/img-8.jpg'),cont:['李楼乡卫生院是郸城县第二医疗集团成员单位，“5G智能国医馆”项目落地实施，通过远程会诊，实现国医大师为基层百姓线上看诊。']},
        {name:'郑州市陇海马路社区服务中心',url:require('../static/img/classicCase/img-11.jpeg'),cont:['经方云为陇海马路社区服务中心量身打造标准化5G智能国医馆，为用户提供：体质检测、智能诊断、远程会诊、健康管理等服务。']},
        {name:'郑州市蜜蜂张社区服务中心',url:require('../static/img/classicCase/img-12.jpg'),cont:['蜜蜂张社区服务中心是一家非营利性、综合性医疗机构，通过云平台建设“智能国医馆”帮助该中心快速成长为中医特色专科诊疗机构。']},
        {name:'三味豫医',url:require('../static/img/classicCase/img-13.png'),cont:['三味豫医是郑州市一家具有中医药特色现代化国医馆，建设“智能国医馆”帮助每位中医师成为“智能老中医”，切实解决病患问题。']},
      ],
      list3: [
        {name:'郸城县陆油坊村卫生室',url:require('../static/img/classicCase/img-14.png'),cont:['陆油坊村卫生室是经方云重磅打造的第一个智能云诊室，通过云平台辅助村卫生室医生准确辨证论治，提升开具处方的质量及安全性。']},
        {name:'郸城县段寨村卫生室',url:require('../static/img/classicCase/img-15.jpg'),cont:['段寨村卫生室通过云平台辅助诊断、智能开方、远程会诊等功能辅助医生诊断，模拟名医专家诊疗思维方式，帮助提升中医诊疗服务能力。']},
        {name:'郸城县魏冢村卫生室',url:require('../static/img/classicCase/img-16.png'),cont:['魏冢村卫生室通过云平台建设智能云诊室，开启智能化辅助诊疗，帮助基层医生临证学习，快速提升中医诊疗服务能力。']},
        {name:'郸城县张号楼社区卫生室',url:require('../static/img/classicCase/img-17.jpg'),cont:['张号楼社区卫生室智能云诊室的建立，帮助许多患者解决了困扰已久的病患问题，患者反响很好，通过口口相传来看诊的患者越来越多。']},
        {name:'郸城县小曹楼卫生所',url:require('../static/img/classicCase/img-18.jpg'),cont:['小曹楼卫生所智能云诊室的启动，为当地村民带来智能诊疗、远程会诊等中医诊疗新模式，足不出村即可享受高质量的中医诊疗服务。']},
      ],
      nowList:[],
    };
  },
  mounted() {
    this.list = this.list1
    this.nowList = this.list.slice(0,3)
    this.total = this.list.length

  },
  methods: {
    changeNav(i){
      if (i == this.navIndex) return
      this.navIndex = i
      if (i === 0){
        this.list = this.list1
      } else if (i === 1){
        this.list = this.list2
      }else {
        this.list = this.list3
      }
      this.nowList = this.list.slice(0,3)
      this.total = 0
      this.$nextTick(()=>{
        this.total = this.list.length
      })
      this.pageChange(1)
    },
    pageChange(page) {
      let num = page -1
      this.nowList = []
      this.nowList = this.list.slice(num * 3,num * 3 + 3)
    },
  },
  components: {
    TopNav,
    Footer
  }
};
</script>
<style lang="scss" scoped>
.first {
  width: 100%;
  height: 401px;
  margin-top: 80px;
  background: url("./../static/img/classicCase/top.png") no-repeat center;
}
.second{
  position: relative;
  padding-top: 83px;
  padding-bottom: 80px;
  width: 1200px;
  margin: 0 auto;
  height: 800px;
  text-align: center;
  .topNav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    margin: 0 auto 50px auto;
    .topNav-item{
      width: 178px;
      height: 52px;
      line-height: 52px;
      cursor: pointer;
      color: #666;
      background-color: white;
      border: 1px solid #666;
      text-align: center;
      font-size: 24px;
      border-radius: 4px;
    }
    .topNav-item-active{
      cursor: pointer;
      color: #fff;
      background-color: #333333;
      border: 1px solid #666;
      text-align: center;
    }
  }

  .title{
    height: 40px;
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
  }
  .title-1{
    height: 19px;
    font-size: 24px;
    font-weight: 300;
    color: #333333;
    margin-bottom: 90px;
  }
  .content{
    display: flex;
    justify-content: space-between;
    align-items: start;
    text-align: left;
    .content-r{
      width: 546px;
      height: 520px;
      .content-r-item{
        display: flex;
        justify-content: left;
        align-items: start;
        margin-bottom: 30px;
        .content-r-item-img{
          margin-right: 20px;
        }
        .content-r-item-txt{
          p{
            line-height: 36px;
            height: 36px;
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            width: 338px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          div{
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            text-align: justify;
            line-height: 27px;
            height: 86px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      & /deep/ .el-pagination {
        margin-top: 20px;
      }

      & /deep/ .el-pagination.is-background .el-pager li {
        background: #ffffff;
        border: 1px solid rgba(189, 154, 115, 1);
        border-radius: 8px;
        width: 50px;
        height: 50px;
        color: #bd9a73;
        font-size: 18px;
        margin: 0 6px;
        line-height: 50px;
      }

      & /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #bd9a73;
        color: #fff;
      }

      & /deep/ .el-pagination.is-background .btn-next,
      & /deep/ .el-pagination.is-background .btn-prev {
        color: #fff;
        background: #d0d0d0;
        margin: 0 24px;
        border-radius: 8px;
        width: 50px;
        height: 50px;
        font-size: 25px;
        line-height: 50px;
        .el-icon{
          font-size: 18px;
        }
      }
    }
  }
}
</style>

